<template>
  <v-card class="elevation-1" tile>
    <v-card-text>
      <validation-observer slim v-slot="{ handleSubmit }">
        <v-row>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'identityNumber')"
              :label="$t('hr.employee.profile.identity_number')"
              rules="required|max:50"
              :index="0"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.identityNumber"
                  :type="showIdentityNumber ? 'text' : 'password'"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  :append-icon="showIdentityNumber ? 'mdi-eye-off' : 'mdi-eye'"
                  counter="50"
                  :error-messages="errors"
                  @click:append="showIdentityNumber = !showIdentityNumber"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'firstName')"
              :label="$t('hr.employee.profile.first_name')"
              rules="required|max:50"
              :index="1"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.firstName"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  counter="50"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'lastName')"
              :label="$t('hr.employee.profile.last_name')"
              rules="required|max:50"
              :index="2"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.lastName"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  counter="50"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'workEmail')"
              :label="$t('hr.employee.profile.work_email')"
              rules="required|email|max:255"
              :index="3"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.workEmail"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 280px;"
                  counter="255"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'phone')"
              :label="$t('hr.employee.profile.phone')"
              rules="required|phone"
              :index="4"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <phone-picker
                  v-model="employeePayload.phone"
                  outlined
                  color="tertiary"
                  style="max-width: 380px;"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'serviceLine.name')"
              :label="$t('hr.employee.profile.service_line')"
              :index="5"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <service-line-picker
                  v-model="employeePayload.serviceLine"
                  dense
                  color="tertiary"
                  style="max-width: 300px;"
                  :placeholder="$t('buttons.please_select')"
                  outlined
                />
              </template>
            </profile-field>
          </v-col>
        </v-row>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
  import { EMPLOYEE_PROFILE } from "../query";

  export default {
    name: "EmployeeProfile",
    props: {
      profileSettings: Object
    },
    components: {
      PhonePicker: () => import("@/components/global/PhonePicker"),
      ServiceLinePicker: () => import("@/components/selectpickers/ServiceLinePicker"),
      ProfileField: () => import("./components/ProfileField")
    },
    data: () => ({
      loading: false,
      employeePayload: {
        identityNumber: null,
        firstName: null,
        lastName: null,
        workEmail: null,
        phone: null,
        serviceLine: null
      },
      employee: null,
      activeIndex: -1,
      saving: false,
      showIdentityNumber: false
    }),
    computed: {
      isEditable() {
        return this.$helpers.get(this.profileSettings, this.$enums.PROFILE_SETTINGS.PROFILE);
      }
    },
    methods: {
      onSaveClick() {
        this.saving = true;
        this.$api.employeeService
          .updateEmployeeCredentials(this.$store.state.auth.user.id, this.employeePayload)
          .then(response => {
            if (response.status === 200) {
              this.activeIndex = -1;
              this.showIdentityNumber = false;
              this.fetchEmployeeProfile();
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.saving = false));
      },
      onCancelClick() {
        this.showIdentityNumber = false;
        this.activeIndex = -1;
        this.setEmployeePayload(this.employee);
      },
      onEditClick(index) {
        this.activeIndex = index;
      },
      setEmployeePayload(employee) {
        this.employeePayload = { ...employee };
        this.employeePayload.serviceLine = this.$helpers.get(employee, "serviceLine.id");
      },
      async fetchEmployeeProfile() {
        this.loading = true;
        this.$apollo
          .query({
            query: EMPLOYEE_PROFILE,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.employee = data.employee;
              this.setEmployeePayload(data.employee);
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.loading = false));
      }
    },
    created() {
      this.fetchEmployeeProfile();
    }
  };
</script>
