var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-1",attrs:{"tile":""}},[_c('v-card-text',[_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'identityNumber'),"label":_vm.$t('hr.employee.profile.identity_number'),"rules":"required|max:50","index":0,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"type":_vm.showIdentityNumber ? 'text' : 'password',"outlined":"","dense":"","color":"tertiary","append-icon":_vm.showIdentityNumber ? 'mdi-eye-off' : 'mdi-eye',"counter":"50","error-messages":errors},on:{"click:append":function($event){_vm.showIdentityNumber = !_vm.showIdentityNumber}},model:{value:(_vm.employeePayload.identityNumber),callback:function ($$v) {_vm.$set(_vm.employeePayload, "identityNumber", $$v)},expression:"employeePayload.identityNumber"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'firstName'),"label":_vm.$t('hr.employee.profile.first_name'),"rules":"required|max:50","index":1,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"outlined":"","dense":"","color":"tertiary","counter":"50","error-messages":errors},model:{value:(_vm.employeePayload.firstName),callback:function ($$v) {_vm.$set(_vm.employeePayload, "firstName", $$v)},expression:"employeePayload.firstName"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'lastName'),"label":_vm.$t('hr.employee.profile.last_name'),"rules":"required|max:50","index":2,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"outlined":"","dense":"","color":"tertiary","counter":"50","error-messages":errors},model:{value:(_vm.employeePayload.lastName),callback:function ($$v) {_vm.$set(_vm.employeePayload, "lastName", $$v)},expression:"employeePayload.lastName"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'workEmail'),"label":_vm.$t('hr.employee.profile.work_email'),"rules":"required|email|max:255","index":3,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"280px"},attrs:{"outlined":"","dense":"","color":"tertiary","counter":"255","error-messages":errors},model:{value:(_vm.employeePayload.workEmail),callback:function ($$v) {_vm.$set(_vm.employeePayload, "workEmail", $$v)},expression:"employeePayload.workEmail"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'phone'),"label":_vm.$t('hr.employee.profile.phone'),"rules":"required|phone","index":4,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('phone-picker',{staticStyle:{"max-width":"380px"},attrs:{"outlined":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.phone),callback:function ($$v) {_vm.$set(_vm.employeePayload, "phone", $$v)},expression:"employeePayload.phone"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'serviceLine.name'),"label":_vm.$t('hr.employee.profile.service_line'),"index":5,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('service-line-picker',{staticStyle:{"max-width":"300px"},attrs:{"dense":"","color":"tertiary","placeholder":_vm.$t('buttons.please_select'),"outlined":""},model:{value:(_vm.employeePayload.serviceLine),callback:function ($$v) {_vm.$set(_vm.employeePayload, "serviceLine", $$v)},expression:"employeePayload.serviceLine"}})]}}],null,true)})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }